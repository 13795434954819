import classNames from 'classnames'
import Image from 'next/image'
import { CSSProperties, useEffect, useState } from 'react'

import { cloudinaryAspectRatioCropLoader } from './loaders/cloudinary'
import styles from './styles.module.scss'

export type ResponsiveImageProps = {
  priority?: boolean
  className?: string
  alt: string
  style?: CSSProperties
  src: string
  suffix?: string
  width: number
  height: number
  sizes?: string
  aspectRatio?: { width: number; height: number }
  loader?: (args) => string
  unoptimized?: boolean
  containerClassName?: string
  isZoomed?: boolean
  onClick?: (event: any) => void
  onPointerMove?: (event: any) => void
  onMouseLeave?: (event: any) => void
  onKeyPress?: (event: any) => void
}

// A wrapper around `next/image` with defaults that make sense for our app
const ResponsiveImage = ({
  priority = false,
  className,
  containerClassName = '',
  alt,
  style,
  src,
  suffix,
  width,
  height,
  sizes = '(max-width: 480px) 62vw, (min-width: 1000px) 25vw',
  aspectRatio = { width, height },
  loader = cloudinaryAspectRatioCropLoader,
  unoptimized = false,
  isZoomed = false,
  ...rest
}: ResponsiveImageProps) => {
  const [blurDataURL, setBlurDataURL] = useState(null)
  const [loaded, setLoaded] = useState(false)
  const shouldUseBlur = width > 150

  useEffect(() => {
    if (shouldUseBlur) {
      const lowQualitySrc = loader({
        src,
        aspectRatio,
        isZoomed: false,
        urlSuffix: suffix,
        quality: '10',
        sizes: '5vw',
        width: width > 1600 || isZoomed ? '100' : '30', // For larger images we need larger placeholders
      })
      setBlurDataURL(lowQualitySrc)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [src, shouldUseBlur])

  if (!src) return null

  return (
    <div style={style} className={containerClassName}>
      <Image
        className={classNames(styles['responsive-image'], className)}
        loader={args => loader({ ...args, aspectRatio, isZoomed, urlSuffix: suffix })}
        alt={alt}
        src={src}
        width={width}
        height={height}
        sizes={sizes}
        priority={priority}
        unoptimized={unoptimized}
        blurDataURL={shouldUseBlur && blurDataURL ? blurDataURL : undefined}
        placeholder={shouldUseBlur && blurDataURL ? 'blur' : 'empty'}
        onLoad={() => setLoaded(true)}
        style={{
          filter: loaded ? 'blur(0px)' : 'blur(20px)',
          transition: 'filter 0.5s linear',
        }}
        {...rest}
      />
    </div>
  )
}

export default ResponsiveImage
