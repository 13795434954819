import { buildUrl, setConfig } from 'cloudinary-build-url'
import getFilename from 'lib/cloudinary'

export const initializeCloudinary = () => {
  setConfig({
    cloudName: 'everlane',
    secure: true,
    secureDistribution: 'media.everlane.com',
    privateCdn: true,
  })
}

initializeCloudinary()

const defaultTransformations = {
  fetchFormat: 'auto',
  dpr: '2.0',
  quality: 'auto:best:sensitive',
}

export const createCloudinaryLoader = getTransformations => {
  return ({ urlSuffix, src, ...args }) =>
    buildUrl(getFilename(src), {
      cloud: { urlSuffix },
      transformations: { ...defaultTransformations, ...getTransformations(args) },
    })
}

export const cloudinaryAspectRatioCropLoader = createCloudinaryLoader(
  ({ aspectRatio, width, isZoomed, quality }) => {
    return {
      crop: 'fill',
      aspectRatio: `${aspectRatio.width}:${aspectRatio.height}`,
      width: isZoomed ? width * 2 : width,
      ...(quality && { quality }),
    }
  },
)
