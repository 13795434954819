/* globals FB */

const GENERIC_ERROR = 'An error occured. Please try again.'
const INSUFFICIENT_PERMISSIONS_ERROR = `
You did not grant permission to use your
email address. Please grant permission by
connecting with Facebook again.
`
const WINDOW_CLOSED_ERROR = 'The Facebook login window was closed. Please try again.'

const Facebook = {
  hasGrantedPermissions() {
    return new Promise((resolve, reject) => {
      FB.api('/me/permissions', response => {
        if (!response.error) {
          const emailPermission = response.data.find(entry => entry.permission === 'email')
          resolve(emailPermission.status !== 'declined')
        } else {
          reject()
        }
      })
    })
  },

  login({ siteLocation = 'generic facebook', extraAuthParams = {}, signIn, setIsLoading } = {}) {
    return new Promise((resolve, reject) => {
      if (typeof FB === 'undefined') {
        reject(new Error(GENERIC_ERROR))
      }

      const params = {
        scope: 'email,public_profile,user_birthday,user_location',
        auth_type: 'rerequest',
        ...extraAuthParams,
        site_location: siteLocation,
      }

      FB.login(async response => {
        if (response.authResponse) {
          const granted = await this.hasGrantedPermissions()

          if (granted && response.status === 'connected') {
            const signInResponse = await signIn(params)

            if (signInResponse) {
              resolve(signInResponse.session)
            } else {
              reject(new Error(GENERIC_ERROR))
            }
          } else {
            reject(new Error(INSUFFICIENT_PERMISSIONS_ERROR))
          }
        }

        setIsLoading(false)
        reject(new Error(WINDOW_CLOSED_ERROR))
      }, params)
    })
  },
}

export default Facebook
